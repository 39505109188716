import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect, useRef, createContext, useMemo } from 'react';
import {
  Avatar,
  Button,
  Stack,
  InputBase,
  Paper,
  Typography,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Container,
  Box,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });


// Styled components
const ChatContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  paddingTop: '20px',
  paddingBottom: '20px',
});

const MessagesContainer = styled(Paper)(({ theme }) => ({
  flex: 1,
  padding: '20px',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
}));

const InputContainer = styled(Paper)(({ theme }) => ({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
}));

const MessageRow = styled('div')<{ sender: string }>(({ sender, theme }) => ({
  display: 'flex',
  justifyContent: sender === 'user' ? 'flex-end' : 'flex-start',
  marginBottom: '15px',
  animation: 'fadeIn 0.5s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },
}));

const MessageBubble = styled(Paper)<{ sender: string }>(({ sender, theme }) => ({
  maxWidth: '70%',
  padding: '10px 15px',
  borderRadius: '20px',
  backgroundColor: sender === 'user' ? theme.palette.primary.main : theme.palette.secondary.main,
  color: sender === 'user' ? '#fff' : '#000',
}));

const AvatarSmall = styled(Avatar)({
  width: '30px',
  height: '30px',
  marginRight: '10px',
});

interface MessageEng {
  index: number;
  message: {
    role: 'user' | 'assistant' | 'system';
    content: string;
    timestamp?: string; 
  };
}

interface DisplayMessage {
  sender: 'user' | 'assistant' | 'system';
  text: string;
  time: string;
}

const initialMessagesEng: MessageEng[] = [
  {
    index: 1,
    message: {
      role: 'system',
      content: "Be a helpful assistant that can help to answer questions for Tibetan people",
    },
  },
];

// Initialize sessionStorage if not present
const initializeSessionStorage = () => {
  if (!sessionStorage.getItem('messages_eng')) {
    sessionStorage.setItem('messages_eng', JSON.stringify({ data: initialMessagesEng }));
  }
};

const ChatGPTUI: React.FC = () => {
  const [input, setInput] = useState<string>('');
  const [messagesDisplay, setMessagesDisplay] = useState<DisplayMessage[]>([]); // Start with empty display
  const [loading, setLoading] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const [mode, setMode] = useState<'light' | 'dark'>('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#1976d2',
          },
          secondary: {
            main: '#e0e0e0',
          },
          background: {
            default: mode === 'light' ? '#f5f5f5' : '#303030',
            paper: mode === 'light' ? '#ffffff' : '#424242',
          },
        },
      }),
    [mode],
  );

  // Initialize sessionStorage on component mount
  useEffect(() => {
    initializeSessionStorage();
  }, []);

  // Event listener to clear sessionStorage on refresh or tab close
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('messages_eng');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Helper function to scroll to bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to bottom whenever messagesDisplay updates
  useEffect(() => {
    scrollToBottom();
  }, [messagesDisplay]);

  const handleSubmit = async () => {
    if (!input.trim()) return; // Prevent empty submissions

    // Retrieve current messages from sessionStorage
    const storedData = sessionStorage.getItem('messages_eng');
    let parsedData = storedData ? JSON.parse(storedData) as { data: MessageEng[] } : { data: [] };

    // Determine the next index
    const nextIndex = parsedData.data.length > 0 ? parsedData.data[parsedData.data.length - 1].index + 1 : 1;

    // Create new user message
    const userMessage: MessageEng = {
      index: nextIndex,
      message: {
        role: 'user',
        content: input,
        timestamp: new Date().toLocaleTimeString(),
      },
    };

    // Update sessionStorage with new user message
    parsedData.data.push(userMessage);
    sessionStorage.setItem('messages_eng', JSON.stringify(parsedData));

    // Update messagesDisplay with new user message
    setMessagesDisplay((prev) => [
      ...prev,
      { sender: 'user', text: userMessage.message.content, time: userMessage.message.timestamp || '' },
    ]);

    setInput('');
    setLoading(true);

    try {
      // Prepare request body
      const requestBody = {
        data: parsedData.data.map((msg) => ({
          index: msg.index,
          message: {
            role: msg.message.role,
            content: msg.message.content,
          },
        })),
      };

      // Send POST request to the API
      const { data } = await axios.post('https://lotsawa-test-api.kundhen.com/chat', requestBody);

      // Extract boe and eng responses
      const boeResponse = data.boe.content;
      const engResponse = data.eng.content;

      // Create assistant message for sessionStorage
      const assistantMessage: MessageEng = {
        index: nextIndex + 1,
        message: {
          role: 'assistant',
          content: engResponse,
          timestamp: new Date().toLocaleTimeString(),

        },
      };

      // Update sessionStorage with assistant message
      parsedData.data.push(assistantMessage);
      sessionStorage.setItem('messages_eng', JSON.stringify(parsedData));

      // Update messagesDisplay with boe assistant message
      setMessagesDisplay((prev) => [
        ...prev,
        { sender: 'assistant', text: boeResponse , time: assistantMessage.message.timestamp || ''},
      ]);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = 'An error occurred. Please try again.';

      // Create assistant error message
      const errorAssistantMessage: MessageEng = {
        index: nextIndex + 1,
        message: {
          role: 'assistant',
          content: errorMessage,
        },
      };

      // Update sessionStorage with error message
      parsedData.data.push(errorAssistantMessage);
      sessionStorage.setItem('messages_eng', JSON.stringify(parsedData));

      // Update messagesDisplay with error message (displayed as assistant)
      setMessagesDisplay((prev) => [
        ...prev,
        { sender: 'assistant', text: errorMessage , time: errorAssistantMessage.message.timestamp || ''},
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <ChatContainer maxWidth="md">
        <Typography variant="h4" align="center" gutterBottom>
        མིས་བཟོས་རིག་སྟོབས། (སྔོན་ལྟ།)🌏
        </Typography>
        <MessagesContainer elevation={3}>
          {messagesDisplay.map((message, index) => (
            <MessageRow key={index} sender={message.sender}>
              {message.sender !== 'user' && (
                <AvatarSmall>
                  {message.sender === 'assistant' ? '🤖' : '📜'}
                </AvatarSmall>
              )}
              <Box>
              <MessageBubble sender={message.sender}>
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </MessageBubble>
              <Typography variant="caption" color="textSecondary" align="right">
        {message.time}
      </Typography>
              </Box>
              {message.sender === 'user' && (
                <AvatarSmall>
                  🧑
                </AvatarSmall>
              )}
            </MessageRow>
          ))}
          {loading && (
            <Box display="flex" justifyContent="center" marginTop="10px">
              <CircularProgress />
            </Box>
          )}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <Box mt={2}>
          <InputContainer elevation={1}>
            <InputBase
              placeholder="🖋️ཁྱེད་རང་གི་དྲི་བ་འདིར་བྲིས།...."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyPress}
              fullWidth
              multiline
              maxRows={4}
              style={{ marginLeft: '10px', marginRight: '10px' }}
            />
            <Divider orientation="vertical" flexItem />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSubmit}
              disabled={loading}
              sx={{ ml: 1 }}
            >
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </InputContainer>
        </Box>
      </ChatContainer>
    </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

// Placeholder translation function
const translateEngToBoe = (engText: string): string => {
  const translations: { [key: string]: string } = {
    "I'm good, thank you!": 'ང་བདེ་ལེགས་གླེང་བ་ཡོད་མི་འདུག།',
    // Add more translations as needed
    'can you say hello?': 'ཁྱེད་རང་གི་བཀྲ་ཤིས་བསྐུར་ཨིན་པས།',
    'how are you?': 'ཁྱེད་གཉིས་ག་རེ་འདུག?',
    'Can you play piano?': 'ཁྱེད་རང་ཨི་ཊེ་ཨེ་པེ་གླེང་བ་རྗེས་ཨིན་པས།',
  };

  return translations[engText] || engText;
};

export default ChatGPTUI;